<template>
  <div class="product-search">
    <div class="product-search__header">
      <a-icon
        :style="{ fontSize: '24px', cursor: 'pointer' }"
        class="product-search__header__search-btn"
        type="search"
        @click="search"
      />
      <input
        v-model="productName"
        class="product-search__header__search-input"
        type="text"
        :placeholder="$t('search.placeholder')"
        @keyup.enter="search"
      />
      <span
        class="product-search__header__cancel-btn"
        @click="cancel"
      >{{ $t('search.cancel') }}</span>
    </div>
    <op-product-list
      ref="opProductList"
      :product-list="productList"
      :loading="loading"
      :total="total"
      @changePage="changePage"
    ></op-product-list>
  </div>
</template>

<script>
import { getProductList } from '@/api'
export default {
  props: {},
  data() {
    return {
      productName: '',
      productList: [],
      total: 0,
      loading: false,
    }
  },

  computed: {},

  methods: {
    cancel() {
      this.$router.back(-1)
    },
    search() {
      this.$refs.opProductList && this.$refs.opProductList.reloadData()
    },
    async getProductList(pagination) {
      const defaultPagination = {
        name: this.productName.trim(),
        size: 12,
        start: 0,
      }
      try {
        this.loading = true
        const { data, totalRecords } = await getProductList({ ...defaultPagination, ...pagination })
        this.productList = data || []
        this.total = totalRecords
      } finally {
        this.loading = false
      }
    },
    changePage(pagination) {
      this.getProductList(pagination)
    },
  },
}
</script>
<style lang="less" scoped>
.product-search {
  width: 1440px;
  max-width: calc(100% - 80px);
  padding: 0 16px;
  margin: 0 auto;
  &__header {
    display: flex;
    align-items: center;
    height: 72px;
    margin-bottom: 24px;
    color: var(--color-primary-button-background);
    border-bottom: solid 1px var(--color-primary-button-background);
    &__search-input {
      flex: 1;
      flex-grow: 100;
      width: 100%;
      height: 100%;
      text-indent: 10px;
      background-color: transparent;
      border: none;
      outline: medium;
    }
    &__search-btn {
      cursor: pointer;
    }
    &__cancel-btn {
      padding: 0 8px;
      cursor: pointer;
    }
  }
}
@media (max-width: 1024px) {
  .product-search {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .product-search {
    max-width: calc(100% - 32px);
    padding: 0;
  }
}
</style>
